<script>
import Layout from "../../layouts/horizontal";
import { mapGetters, mapActions } from 'vuex';
import PageHeader from "@/components/page-header";

export default {
  components: {
    Layout,
    PageHeader
  },
  created() {
    this.loadLiveIndicies();
  },
  data() {
    return {
      title: "Execution",
       breadcrumbs: [
      ]
    };
  },
  computed:{
    ...mapGetters('execution', {
     // isBackendAlive: 'isBackendAlive',
      liveIndicies: 'liveIndicies',
      loading: 'loading'
    })
  },
  watch: {
    // isBackendAlive: {
    //   handler: function () {
    //     if(this.isBackendAlive) {
    //       this.loadLiveIndicies();
    //     }
    //   }
    // }
  },
  methods: {
    ...mapActions('execution', {
      loadLiveIndicies: 'loadLiveIndicies'
    })
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="breadcrumbs" :is-busy="loading"/>
    <div class="d-flex d-flex justify-content-end">
      <a href="https://c8studio2022.blob.core.windows.net/setups/c8gw_Win.1.0.0.zip" class="btn btn-c8 mb-1">
        <span class="btn-content">
          <i class="fa fa-download mr-1"></i>
          Download Gateway
        </span>
       
      </a>
    </div>
    <div class="card cardc8">
      <div class="card-content m-3">
       
        <div class="table-responsive">
          <table class="table table-centered nowrap">
            <thead>
              <tr>
                <th>
                  Name
                </th>
                <th>
                  Status
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="liveIndicies.length === 0">
                <td colspan="100">
                  <span class="text-muted">No records found...</span>
                </td>
              </tr>
              <tr v-for="item in liveIndicies" :key="item.id">
                <td>
                  <router-link :to="{name: 'execution-item', params: { id: item.id}}" v-if="!item.status">
                    {{item.name}}
                  </router-link>
                  <span v-if="item.status">
                    {{item.name}}
                  </span>
                </td>
                <td>
                  <span v-if="!item.status" class="badge badge-success">ready</span>
                  <span v-if="item.status" class="badge badge-info">{{ item.status }}</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  
    
  </Layout>
</template>